<template>
    <div>
      <span v-if="user_status == 'true'">
      <CCard>
        <CCardHeader class="text-center">
          <CIcon name="cil-grid" /><strong>Checks Report</strong>
          <CRow class="mt-3">
            <CCol class="col-md-6 col-12">
              <CCard class="p-2">
                <CRow>
                  <CCol>
                  <label class="mt-2">Date Range</label>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                      {{ changeDateFormateForCalender(startDate)}}   <b>-</b>  {{ changeDateFormateForCalender(endDate) }}
                    </span>
                  </CCol>
                <CModal
                  title="Select Date Range"
                  :show.sync="date_modal"
                  :centered="true"
                  scrollable
                  color="dark"
                  
                >
                <date-range-picker 
                  :from="date_data.endDate"
                  :to="date_data.startdate"
                  :future="false"
                  :panel="date_data.panel"
                  :showControls="false" 
                  @select="update"/>
                <template #footer>
                  <CButton @click="date_modal = false" color="danger">Close</CButton>
                </template>
                </CModal>
                </CRow>
                <CRow>
                  <CCol class="mt-2 col-md-8">
                    <label class="ml-2 mt-2">TimeZone</label>
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      track-by="value"
                      label="text"
                      :multiple="false"
                      :options="timezoneopt"
                      :preselect-first="true"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="filter_dash.timezoneopt"
                    >
                    </multiselect>
                    </CCol>
                    <CCol class="mt-2">
                    <label class="ml-2 mt-2">View By</label>
                    <CSelect
                      required
                      :value.sync="filter_dash.granularity"
                      :options="granularity_opt"
                    />
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol class="col-md-12">
                    <label>Group By</label>
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      track-by="value"
                      label="text"
                      :multiple="true"
                      :options="projectionopt"
                      :preselect-first="true"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="filter_dash.projectionopt"
                    >
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow class="pb-5">
                  <CCol class="col-md-12">
                  <label class="ml-2 mt-2">Dimension</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="false"
                    track-by="value"
                    label="text"
                    :multiple="true"
                    :options="metricsopt"
                    :preselect-first="true"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="filter_dash.metricsopt"
                  >
                    <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="filter_dash.metrics.length &amp;&amp; !isOpen">{{ filter_dash.metrics.length }} options selected</span></template> -->
                  </multiselect>
                  </CCol>
                </CRow>
              </CCard>
            </CCol>
            <CCol class="col-md-6 col-12">
              <CCard class="p-3">
                <CRow class="col-md-12 col-12">
                  <label>Filter</label>
                </CRow>
                <!-- Filter1 -->
                <CRow class="">
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      track-by="value"
                      label="text"
                      :options="projectionopt"
                      :preselect-first="false"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="dimfilter"
                      class="filter1"
                    ></multiselect>
                  </CCol>
                  <CCol class="col-md-1">
                    <span style="font-size: 1.2rem"><strong>=</strong></span>
                  </CCol>
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      track-by="value"
                      label="text"
                      :options="dimentionopt2"
                      :preselect-first="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      v-model="dimopt"
                      :taggable="true"
                      @tag="addTag1"
                      class="filter1"
                    >
                    </multiselect>
                  </CCol>
                  <CCol
                    class="col-md-1 mt-2"
                    v-show="this.filter_fields.filter_1 == false"
                  >
                    <span @click="Clone('add', 1)">
                      <CImg
                        src="img/logo/plus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                </CRow>
                <!-- Filter2 -->
                <CRow class="mt-2" v-show="filter_fields.filter_1">
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      track-by="value"
                      label="text"
                      :options="projectionopt"
                      :preselect-first="false"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="dimfilter_f1"
                      class="filter1"
                    ></multiselect>
                  </CCol>
                  <CCol class="col-md-1">
                    <span style="font-size: 1.2rem"><strong>=</strong></span>
                  </CCol>
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      track-by="value"
                      label="text"
                      :options="dimentionopt2_f1"
                      :preselect-first="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      v-model="dimopt_f1"
                      :taggable="true"
                      @tag="addTag2"
                      class="filter1"
                    >
                    </multiselect>
                  </CCol>
                  <CCol
                    class="col-md-1 mt-2"
                    v-show="this.filter_fields.filter_2 == false"
                  >
                    <span @click="Clone('add', 2)">
                      <CImg
                        src="img/logo/plus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                  <CCol class="col-md-1 mt-2">
                    <span @click="Clone('remove', 1)">
                      <CImg
                        src="img/logo/minus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                </CRow>
                <!-- Filter3 -->
                <CRow class="mt-2" v-show="filter_fields.filter_2">
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      track-by="value"
                      label="text"
                      :options="projectionopt"
                      :preselect-first="false"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="dimfilter_f2"
                      class="filter1"
                    ></multiselect>
                  </CCol>
                  <CCol class="col-md-1">
                    <span style="font-size: 1.2rem"><strong>=</strong></span>
                  </CCol>
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      track-by="value"
                      label="text"
                      :options="dimentionopt2_f2"
                      :preselect-first="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      v-model="dimopt_f2"
                      :taggable="true"
                      @tag="addTag3"
                      class="filter1"
                    >
                    </multiselect>
                  </CCol>
                  <CCol
                    class="col-md-1 mt-2"
                    v-show="this.filter_fields.filter_3 == false"
                  >
                    <span @click="Clone('add', 3)">
                      <CImg
                        src="img/logo/plus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                  <CCol class="col-md-1 mt-2">
                    <span @click="Clone('remove', 2)">
                      <CImg
                        src="img/logo/minus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                </CRow>
                <!-- Filter4 -->
                <CRow class="mt-2" v-show="filter_fields.filter_3">
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      track-by="value"
                      label="text"
                      :options="projectionopt"
                      :preselect-first="false"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="dimfilter_f3"
                      class="filter1"
                    ></multiselect>
                  </CCol>
                  <CCol class="col-md-1">
                    <span style="font-size: 1.2rem"><strong>=</strong></span>
                  </CCol>
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      track-by="value"
                      label="text"
                      :options="dimentionopt2_f3"
                      :preselect-first="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      v-model="dimopt_f3"
                      :taggable="true"
                      @tag="addTag4"
                      class="filter1"
                    >
                    </multiselect>
                  </CCol>
                  <CCol
                    class="col-md-1 mt-2"
                    v-show="this.filter_fields.filter_4 == false"
                  >
                    <span @click="Clone('add', 4)">
                      <CImg
                        src="img/logo/plus_icon.png"
                        cnewofferlass="img-fluid ml-1"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                  <CCol class="col-md-1 mt-2">
                    <span @click="Clone('remove', 3)">
                      <CImg
                        src="img/logo/minus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                </CRow>
                <!-- Filter5 -->
                <CRow class="mt-2" v-show="filter_fields.filter_4">
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      track-by="value"
                      label="text"
                      :options="projectionopt"
                      :preselect-first="false"
                      :preserve-search="false"
                      placeholder="Pick some"
                      v-model="dimfilter_f4"
                      class="filter1"
                    ></multiselect>
                  </CCol>
                  <CCol class="col-md-1">
                    <span style="font-size: 1.2rem"><strong>=</strong></span>
                  </CCol>
                  <CCol class="col-md-4 col-12">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      track-by="value"
                      label="text"
                      :options="dimentionopt2_f4"
                      :preselect-first="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      v-model="dimopt_f4"
                      :taggable="true"
                      @tag="addTag5"
                      class="filter1"
                    >
                    </multiselect>
                  </CCol>
  
                  <CCol class="col-md-1 col-12 mt-2">
                    <span @click="Clone('remove', 4)">
                      <CImg
                        src="img/logo/minus_icon.png"
                        title="add"
                        style="cursor: pointer"
                      ></CImg>
                    </span>
                  </CCol>
                  <CCol class="col-md-1"> </CCol>
                </CRow>
              </CCard>
            </CCol>
          </CRow>
          <CRow class="">
            <CCol class="text-center">
              <CButton class="col-md-2 col-12" color="success" @click="OnSubmit()"
                >Get Report</CButton
              >
              <vue-csv-downloader
                class="ml-md-4"
                :data="items"
              >
                <CButton class="col-md-2 col-12 mt-md-0 mt-2" color="info"
                  >CSV</CButton
                >
              </vue-csv-downloader>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="text-center">
          <div v-if="loading == true">Loading . . .</div>
          <div v-else>
            <CRow>
              <CCol>
                <CRow class="col-md-12">
                  <!-- <label>Filter :</label>
                  <CInput
                    class="col-md-8"
                    @keyup="customFilter"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  /> -->
                  <label>Total Rows : {{ totalRows }}</label>
                </CRow>
              </CCol>
              <CCol>
                <div class="card-header-actions">
                  <small class="text-muted">
                    <multiselect
                      :limit="2"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :multiple="false"
                      :options="perPage"
                      :preselect-first="false"
                      :preserve-search="false"
                      @input="checkPage()"
                      placeholder="PerPage"
                      v-model="tableperpage"
                    ></multiselect
                  ></small>
                </div>
              </CCol>
            </CRow>
            <!-- <span  v-if="loading">Loading ...</span>
          <span v-else> -->
            <div class="table_div">
            <CDataTable
              :sorterValue.sync="sortDesc"
              :items="items"
              :dark="false"
              :items-per-page="tableperpage"
              pagination
              :sorter='{ external: true, resetable: true }'
              hover
              striped
              border
              small
              fixed
              column-filter
            >
              <template #date="{ item }">
                <td>
                  {{ changeDateFormate(item.date) }}
                </td>
                <!-- {{item.date}} -->
              </template>
              <!-- column-filter -->
              <template #campname="{ item }"
                ><td>
                  <span
                  >
                    {{ item.campname }}</span
                  >
                </td></template
              >
  
              <template #offerrev="{ item }"
                ><td>{{ item.offerrev.toFixed(2) }}</td></template
              >
  
              <template #camprev="{ item }"
                ><td>{{ item.camprev.toFixed(2) }}</td></template
              >

              <!-- custom labels -->
              <template #fastatus-header="data">
                  <span>Fraud Status</span>
              </template>
              <template #checkname-header="data">
                  <span>Check Name</span>
              </template>
            </CDataTable>
            <div class="pagination">
              <a href="#" v-if="currentpage != 1" @click="runQuery('','prev')" title="previous">&laquo;</a>
              <a href="#" class="active">{{currentpage}}</a>
              <a href="#" v-if="items.length >= tableperpage" @click="runQuery('','next')" title="next">&raquo;</a>
            </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
  
      </span>
      <span v-else>
        <CCard class="col-md-12">
           <h4 style="color:red;">Your Account is not activated</h4>
          <label>please contact to Manager</label>
        </CCard>
      </span>
    </div>
  </template>
  
  
  <script>
  import ManagerService from "./../services/managerControlService";
  import ModeratorService from "./../services/moderatorControlService";
  import Multiselect from "vue-multiselect";
  import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
  import { DateTimePicker } from "@lazy-copilot/datetimepicker";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import VueCsvDownloader from 'vue-csv-downloader';
  import TimeZone from "./../services/timezones";
  var timezonelist = TimeZone
  export default {
    name: "Dashboard",
    components: {
      Multiselect,
      DateTimePicker,
      VueCsvDownloader,
      TimeZone
    },
    data() {
      return {
        currentpage: 1,
        date_data:{startdate:null,enddate:null,panel:null},
        date_modal:false,
        sortDesc:{},
        all_data:[],
        filter: '',
        totalRows:0,
        filter_fields:{filter_1 :false,filter_2:false,filter_3:false,filter_4:false},
        tableperpage:1000,
        perPage:[20,50,100,500,1000,10000],
        items: [],
        user: "",
        user_status: false,
        timezoneopt:timezonelist.map((t)=>{return {value:t.tzCode,text:t.label}}),
        filter_dash: {
          edate: new Date(),
          sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          dim: {},
          granularity: "daily",
          projection: [],
          metrics: [],
          projectionopt:[],
          metricsopt:[],
          sortBy: "date",
          sortAescDir: false,
          timezoneopt:{ text: '(GMT+00:00) London', value: 'Europe/London' }
        },
        startDate: new Date(),
        endDate: new Date(),
        fields: [],
        dimfilter: "",
        dimfilter_f1 :"",
        dimfilter_f2 :"",
        dimfilter_f3 :"",
        dimfilter_f4 :"",
        projectionopt: [
            { value: "checkname", text: "Check Name" },
            { value: "external_id", text: "External Id" },
            { value: "camp_id", text: "Campaing ID" }
        ],
        metricsopt: [
            { value: "status", text: "Status" },
            { value: "fastatus", text: "Fraud Status" }
        ],
        dimentionopt: [],
        granularity_opt: ["daily", "hourly","monthly"],
        dimentionopt2: [],
        dimentionopt2_f1: [],
        dimentionopt2_f2: [],
        dimentionopt2_f3: [],
        dimentionopt2_f4: [],
        dimopt: [],
        dimopt_f1: [],
        dimopt_f2: [],
        dimopt_f3: [],
        dimopt_f4: [],
        url: "",
        // collapse: false,
        csvFields: [],
        change_date : false,
        loading:false,
        projectionopt_val:[],
        metricsopt_val:[]
      };
    },
    watch: {
      sortDesc: function (val) {
        this.runQuery(val)
      },
    },
    async mounted() {
      if (
        !window.localStorage.getItem("user") ||
        window.localStorage.getItem("user") == "null"
      ) {
        // alert("Please Enter User Name and Password");
        this.$router.push("/pages/login");
      } else {
        if(localStorage.getItem("checkrep_table") != null){
        this.tableperpage = parseInt(localStorage.getItem("checkrep_table"));
        }
        this.user = window.localStorage.getItem("user");
        if(this.user == 'manager'){
            this.projectionopt.push({ value: "value", text: "Value" })
        }
        this.user_status = window.localStorage.getItem("status");
        this.filter_dash.projection = ['checkname']
        this.projectionopt_val = [{ value:"checkname",text:"Check Name" }]
        if(this.user_status == 'true'){
        this.dateFilter({startDate:new Date(this.startDate.setHours(0,0)),endDate:new Date(this.endDate.setHours(23,59))});
        
        // this.runQuery();
        
        }
      }
    },
    methods: {
      changeDateFormateForCalender(d){
        let dateToFormate
        if(typeof d != "string") {
          dateToFormate = new Date(d);
        } else {
          let dd = d.split("T")[0]
          dateToFormate = new Date(dd);
        } 
       return dateToFormate.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
      },
      update(values) {
        this.date_data.startdate = values.from
        this.date_data.enddate = values.to
        this.date_data.panel = values.panel
        this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
        this.date_modal = false;
      },
      customFilter() {
        let search = this.filter.toString().toLowerCase()
        if(search != ''){
        let f_data = this.all_data.filter(row => row.campname.match(search))
        if(f_data){return this.items = f_data}
        }
        else{return this.items = this.all_data}
      },
      Clone(txt,count){
        if(txt == 'add'){
        switch(count){
          case 1 : this.filter_fields.filter_1 = true
          break;
          case 2 : this.filter_fields.filter_2 = true
          break;
          case 3 : this.filter_fields.filter_3 = true
          break;
          case 4 : this.filter_fields.filter_4 = true
          break;
        }
        }
        if(txt == 'remove'){
          switch(count){
          case 1 : this.filter_fields.filter_1 = false
                   this.dimfilter_f1 = ""
                   this.dimopt_f1 = []
                   this.dimentionopt2_f1 = []
          break;
          case 2 : this.filter_fields.filter_2 = false
                   this.dimfilter_f2 = ""
                   this.dimopt_f2 = []
                   this.dimentionopt2_f2 = []
          break;
          case 3 : this.filter_fields.filter_3 = false
                   this.dimfilter_f3 = ""
                   this.dimopt_f3 = []
                   this.dimentionopt2_f3 = []
          break;
          case 4 : this.filter_fields.filter_4 = false
                   this.dimfilter_f4 = ""
                   this.dimopt_f4 = []
                   this.dimentionopt2_f4 = []
          break;
        }
        }
      },
      changeDateFormate(d){
        var p = new Date(d);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(p);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(p);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(p);
        let hh = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(p);
        var dd
        if(this.change_date){
         dd = `${ye} ${mo} ${da} , ${hh}`;
        }
        else{ dd = `${ye} ${mo} ${da}` }
        return  dd
      },
      checkPage(){
        localStorage.setItem("checkrep_table", this.tableperpage);
      },
      addTag1(newTag) { this.dimopt.push({value:newTag,text:newTag}); },
      addTag2(newTag) { this.dimopt_f1.push({value:newTag,text:newTag}); },
      addTag3(newTag) { this.dimopt_f2.push({value:newTag,text:newTag}); },
      addTag4(newTag) { this.dimopt_f3.push({value:newTag,text:newTag}); },
      addTag5(newTag) { this.dimopt_f4.push({value:newTag,text:newTag}); },
      dateFilter(data) {
        this.filter_dash.sdate = new Date(
          data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
        ).toISOString();
        this.filter_dash.edate = new Date(
          data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
        ).toISOString();
        this.startDate = data.startDate
        this.endDate = data.endDate
        // this.filter_dash.sdate = (new Date(data.startDate)).toISOString();
        // this.filter_dash.edate = (new Date(data.endDate)).toISOString()
      },
      OnSubmit(){
        this.filter_dash.projection = [];
        this.runQuery()
      },
      runQuery: async function(sort, pagination) {
        if(pagination == 'next'){this.currentpage ++}
        else if(pagination == 'prev'){
            if(this.currentpage != 1){
            this.currentpage --
            }
          }
        else{this.currentpage = 1}
        this.startDate = new Date(this.startDate.setHours(0,0))
        this.endDate = new Date(this.endDate.setHours(23,59))
        this.loading = true
        this.change_date = false
        if(this.filter_dash.granularity == "hourly"){
          this.change_date = true
        }
          this.filter_dash.dim = {}
          this.pushTodim();
          this.filter_dash.projectionopt.map((m) => {
            this.filter_dash.projection.push(m.value);
            this.projectionopt_val.push({value:m.value, text: m.text})
          });
          this.filter_dash.metrics = []
            this.filter_dash.metricsopt.map((m) => {
                this.filter_dash.metrics.push(m.value);
                this.metricsopt_val.push({value:m.value, text: m.text})
            });
          var response
          if(sort){
            this.filter_dash.sortBy = sort.column
            this.filter_dash.sortAescDir = sort.asc
          }
          
          let payload = this.filter_dash
          payload.page = this.currentpage
          payload.limit = this.tableperpage
          // remove projectionopt from above object
            // delete payload.projectionopt
            // delete payload.metricsopt
          if(this.user == 'manager'){
            response = await ManagerService.ChecksReport(payload);
          }
          if(this.user == 'moderator'){
            response = await ModeratorService.ChecksReport(payload);
          }
          if(response.result){
          this.loading = false
          this.items = [];
          if(response.data){
            this.items = response.data;
            this.all_data = response.data;
            //   this.metricsopt_val=[]
            //   this.projectionopt_val = []
            this.totalRows = this.items.length;
          }
          }
      },
      pushTodim() {
        // let cake = this.filter_dash.dim;
        if(this.dimfilter != null){
        if (this.dimfilter.value) {
          this.filter_dash.dim[this.dimfilter.value] = this.dimopt.map(r=>r.value);
        } else {
          this.filter_dash.dim = {};
        }
        if (this.dimfilter_f1.value) {
          this.filter_dash.dim[this.dimfilter_f1.value] = this.dimopt_f1.map(r=>r.value);
        }
        if (this.dimfilter_f2.value) {
          this.filter_dash.dim[this.dimfilter_f2.value] = this.dimopt_f2.map(r=>r.value);
        }
        if (this.dimfilter_f3.value) {
          this.filter_dash.dim[this.dimfilter_f3.value] = this.dimopt_f3.map(r=>r.value);
        }
        if (this.dimfilter_f4.value) {
          this.filter_dash.dim[this.dimfilter_f4.value] = this.dimopt_f4.map(r=>r.value);
        }
        }
      },
    },
  };
  </script>
  <style scoped>
  .link {
    color: blue;
  }
  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .f_button{
    margin-top: 35px;
  }
  </style>
  <style>
  .table_div{
    overflow: auto; height: 500px;
  }
  .table_div thead { position: sticky !important; top: 0 !important; z-index: 1 !important; }
  table  { border-collapse: collapse; width: 100%; }
  th, td { padding: 8px 16px; }
  th     { background:#eee; }
  .table-responsive{
    overflow: unset !important;
  }
  .dim{
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .metrics{
    max-width: 110px;
  }
  .projection{
    max-width: 100px;
  }
  .filter1:focus-within .multiselect__content-wrapper{
    width: 300px !important;
  }
  .pagination {
  display: block;
}
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    font-size: 22px;
  }

  .pagination a.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }

  .pagination a:hover:not(.active) {background-color: #ddd;}
  </style>