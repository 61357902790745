import api from './api'

export default {
    getuser(){
    return api.execute(`get`,`/moderator/users`)
    },
    changestatus(data){
      return api.execute(`put`,`/moderator/users`,data)
    },
    ChecksReport(data){
      return api.execute(`post`,`/moderator/checks/report`,data)
    },
}

